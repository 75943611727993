import React from 'react'

const TeacherFormSubmitted = () => {
  return (
    <>
    <div className='container mt-5'>
    <div className='row  mt-5 add-more-text-full-div ma'>
            <div className='col-10 border-l border-left-none'>
                <div className='w-100 icon-full-div'>
                  <div className='bg-clr-green h-100 icon-div position-relative'>
                   <div className='skew-div position-absolute h-100 position-absolute'>
                    </div>
                   <i class="fa-solid fa-check" style={{color: "white",
    fontWeight: "900",
    fontSize: "33px",
    textAlign: "center",
    marginLeft: "8px"}}></i>

                     </div>
                  <p className='verification-text' style={{paddingTop:"0",marginLeft:"20px"}}>Subject added</p>
                </div>
                
            </div>
            <div className='col-2 border-r'>
                  <i class="fa-solid fa-delete-left" style={{marginTop:"13px",marginTop: "13px",
    float: "right"}}></i>
            </div>
        
        
            <div className='col'>
              <div className=''>
                
     <h3 className='mt-3 text-align-left'>Subjects you Teach <span className='txt-green sub-head' style={{color:"rgb(173,205,75)"}}
     >Add a subject</span></h3>
     </div>
      <div className="full-div d-flex ma mt-4" >
        <i class="fa-solid fa-pen-to-square" style={{marginTop: "10px"}}></i>
        <i class="fa-solid fa-trash trash" ></i>
        <p  >English and Literature(Grade1 to Grade6)</p>
        </div>
       <div className=' mt-5  '>
        <h4 style={{float:"left"}} className='ma'>Add more subjects <span className=' sub-head' style={{color:"rgb(173,205,75)"}}
     >or go to next subjects </span></h4><br/><br/>

        <p style={{float:"left"}}>Subject(one at a time)</p><br/><br/>
        <input type="text" style={{float:"left"}}  placeholder="Enter Subject" className='w-100 border'/><br/><br/>
        <h6 style={{float:"left"}}>If not in options above ,add a new subject</h6><br/><br/>
        <button type='button' className="btn mt-3"  style={{float:"left",backgroundColor: "rgb(173, 205, 75)",color: "white" }}>save</button>
    
     </div>
     </div>
     </div>
        </div>
    
    
    </>
  )
}

export default TeacherFormSubmitted




