import React from 'react'
import "./ft.css";
import { NavLink } from 'react-router-dom';

const Findtutor = () => {
  return (
 <>
 <main className="flex-shrink-0">
     
 <div className='container-fluid'>
 <div className='row content'>
    <div className='col-lg-5 div-1'>
    <h1 className='Educate-text-cls'>FIND BEST!</h1>
    <h1 className='position-relative smart-text'>TUTORS JOBS</h1>
   <p>
   Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
ipsum suspendisse ultrices gravida. Risus commodo viverra
maecenas accumsan lacus vel facilisis   </p>
    </div>  
    <div className='col-lg-7'>
       <img src="teacher-mk/19874.jpg" alt="Not loading" className='img-fluid'/>
    </div>

 </div>
  
 </div>
        <div className="container px-5">
          <aside className="p-sm-4">
            <div className="d-flex align-items-center justify-content-between flex-column flex-xl-row text-center text-xl-start">
              <div className="mb-4 mb-xl-0">
              <input className="form-control" type="text" placeholder="Subjects/Skills" aria-label="Email address..." aria-describedby="button-newsletter" />
  </div><div className="mb-4 mb-xl-0">
              <input className="form-control" type="text" placeholder="Location" aria-label="Email address..." aria-describedby="button-newsletter" />
  </div><div className="mb-4 mb-xl-0">
  <button className="btn btn-outline-light" id="button-newsletter" type="button">Search</button>
  </div>
              {/* <div className="ms-xl-4">
                <div className="input-group mb-2">
                  <input className="form-control" type="text" placeholder="Email address..." aria-label="Email address..." aria-describedby="button-newsletter" />
                  <button className="btn btn-outline-light" id="button-newsletter" type="button">Sign up</button>
                </div>
                <div className="small text-white-50">We care about privacy, and will never share your data.</div>
              </div> */}
            </div>
          </aside>
        </div>
        <section className="py-5">
          <div className="container ">
            <div className="row gx-5">
              <div className="col-xl-8">
              <NavLink to="/job-details" > <h4 className="fw-bolder mb-3">
                 
                     English Home tutor required in karachi
                   
                   </h4></NavLink>
                <div className="accordion mb-5" id="accordionExample">
                  <div className="accordion-item">
                    <h3 className="accordion-header" id="headingOne"><button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">English</button></h3>
                    <div className="accordion-collapse collapse show" id="collapseOne" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.

                      </div>
                      <p>
                      <img src="teacher-mk/calender.png" alt="Not loading" className='img-fluid1'/>1 mins ago  <img src="teacher-mk/location.png" alt="Not loading" className='img-fluid1'/> Jalalpur Jattan, Gujrat, Punjab, Pakistan ~£60month  
                   </p>                    </div>
                  </div>
             
                 
                </div>
                {/* FAQ Accordion 2*/}
                <NavLink to="/job-details" > <h4 className="fw-bolder mb-3">
                  Online Financial Accounting tutor needed in Ennore</h4>
                  </NavLink>
                <div className="accordion mb-5 mb-xl-0" id="accordionExample2">
                  <div className="accordion-item">
                    <h3 className="accordion-header" id="headingOne"><button className="accordion-button" type="button" >Accounting </button></h3>
                    <div className="accordion-collapse collapse show" id="collapseOne2" aria-labelledby="headingOne" data-bs-parent="#accordionExample2">
                      <div className="accordion-body">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.

Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.

                      </div>
                      <p>
                      <img src="teacher-mk/calender.png" alt="Not loading" className='img-fluid1'/>1 mins ago  <img src="teacher-mk/location.png" alt="Not loading" className='img-fluid1'/> Jalalpur Jattan, Gujrat, Punjab, Pakistan ~£60month  
                   </p>                                  </div>
                  </div>
                 
               
                </div>
<br></br><br></br>
<NavLink to="/job-details" > <h4 className="fw-bolder mb-3">
Online Maths, Mathematics, NDA tutor required in Noida</h4></NavLink>
  
                <div className="accordion mb-5" id="accordionExample">
                  <div className="accordion-item">
                    <h3 className="accordion-header" id="headingOne"><button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Maths</button></h3>
                    <div className="accordion-collapse collapse show" id="collapseOne" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.

                      </div>
                      <p>
                      <img src="teacher-mk/calender.png" alt="Not loading" className='img-fluid1'/>1 mins ago  <img src="teacher-mk/location.png" alt="Not loading" className='img-fluid1'/> Jalalpur Jattan, Gujrat, Punjab, Pakistan ~£60month  
                   </p> </div>
                  </div>
             
                 
                </div>

          
                <NavLink to="/job-details" > <h4 className="fw-bolder mb-3">Online | home GMAT Quantitative teacher needed in Prey Sar</h4></NavLink>
                <div className="accordion mb-5" id="accordionExample">
                  <div className="accordion-item">
                    <h3 className="accordion-header" id="headingOne"><button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">GMAT</button></h3>
                    <div className="accordion-collapse collapse show" id="collapseOne" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.

                      </div>
                      <p>
                      <img src="teacher-mk/calender.png" alt="Not loading" className='img-fluid1'/>1 mins ago  <img src="teacher-mk/location.png" alt="Not loading" className='img-fluid1'/> Jalalpur Jattan, Gujrat, Punjab, Pakistan ~£60month  
                   </p>                    </div>
                  </div>
             
                 
                </div>
               
                <NavLink to="/job-details" > <h4 className="fw-bolder mb-3">Cryptocurrency Home teacher required in Tiruvallur</h4></NavLink>
                <div className="accordion mb-5" id="accordionExample">
                  <div className="accordion-item">
                    <h3 className="accordion-header" id="headingOne"><button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Cryptocurrency</button></h3>
                    <div className="accordion-collapse collapse show" id="collapseOne" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.

                      </div>
                      <p>
                      <img src="teacher-mk/calender.png" alt="Not loading" className='img-fluid1'/>1 mins ago  <img src="teacher-mk/location.png" alt="Not loading" className='img-fluid1'/> Jalalpur Jattan, Gujrat, Punjab, Pakistan ~£60month  
                   </p>                    </div>
                  </div>
             
                 
                </div>

              </div>

              
              <div className="col-xl-4">
                <div className="card border-0 mt-xl-5">
                  <div className="card-body p-4 py-lg-5">
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="text-center">
                        <div className="h3 fw-bolder">Location</div>
                        
                       
                     
                      </div>
                    </div>
                    <ul class="list-group list-group-flush">
                  
    <li class="list-group-item">Afghanistan</li>
    <li class="list-group-item">Australia</li>
    <li class="list-group-item">Albania</li>
    <li class="list-group-item">Algeria</li>
    <li class="list-group-item">Angola</li>
    <li class="list-group-item">Anguilla</li>
    <li class="list-group-item">Argentina</li>
    <li class="list-group-item">Armenia</li>
    <li class="list-group-item">Australia</li>
    <li class="list-group-item">Austria</li>
    <li class="list-group-item">Azerbaijan</li>
    <li class="list-group-item">Bahrain</li>
    <li class="list-group-item">Bangladesh</li>
    <li class="list-group-item">Belarus</li>
    <li class="list-group-item">Belgium</li>
    <li class="list-group-item">Bermuda</li>
    <li class="list-group-item">Botswana</li>
    <li class="list-group-item">Brazil</li>
    <li class="list-group-item">Brunei</li>
    <li class="list-group-item">Burundi</li>
    <li class="list-group-item">Cambodia</li>
    <li class="list-group-item">Cameroon</li>
    <li class="list-group-item">Canada</li>
    <li class="list-group-item">Cayman Islands</li>
</ul>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
 </>
  )
}

export default Findtutor