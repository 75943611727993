import React from 'react'
import "./App.css";
import { NavLink } from 'react-router-dom';

const Header = () => {
  return (
    <>
   
   <nav className="navbar navbar-expand-lg bg-body-tertiary border-btm">
  <div className="container-fluid">
  <img src="teacher-mk/logo.png" alt="Logo image" className='img-fluid logo-cls'/>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarText">
      <ul className="navbar-nav  mb-2 mb-lg-0 me-28">
      <NavLink to="/" style={{textDecoration:"none"}}>
        <li className="nav-item">
          <a className="nav-link active" style={{textDecoration:"none"}} aria-current="page" href="#" >Home</a>
        </li>
        </NavLink>
        <NavLink to="/findtutor" style={{textDecoration:"none"}}>
        <li className="nav-item">
          <a className="nav-link" href="#">Find tutors</a>
        </li></NavLink>
          <NavLink to="teacher-link" style={{textDecoration:"none"}}>
        <li className="nav-item">
     <a className="nav-link" href="#">Find tutors Jobs</a>
        </li>
        </NavLink> 
        <li className="nav-item">
          <a className="nav-link" href="#">Assignment help</a>
        </li>
        <NavLink to="/wallet" style={{textDecoration:"none"}}>
        <li className="nav-item">
     <a className="nav-link" href="#">Wallet</a>
        </li>
        </NavLink> 
              </ul>
      <div className=" login-btn ">
      <NavLink to="/login" style={{textDecoration:"none"}} className='login-cls'>Login</NavLink> 
      &nbsp;|&nbsp;
      <NavLink to="/request-tutor" style={{textDecoration:"none"}} className='login-cls'>
      Request a tutor 
       </NavLink>
      </div>
    </div>
  </div>
</nav>



 
  </>
  )
}

export default Header