import React from 'react'

const TeachersForm = () => {
  return (
    <>
        <div className='container mt-5'>
            <div className='d-flex '>
            <h3 className='ma '>Subjects you Teach <span className='txt-green sub-head' style={{color:"rgb(173,205,75)"}}>Add a subject</span></h3>
                </div>
                <div className='row'>
                 <div className='col-xl-5 ma mt-3'>
                    <h6 className='mt-5'  style={{float:"left"}}>Subject(one at time)</h6>

                 <select class="form-select " aria-label="Default select example">
                 <option selected>Open this select menu</option>
                 <option value="1">One</option>
                 <option value="2">Two</option>
                 <option value="3">Three</option>
                </select>

                <h6 className='mt-5' style={{float:"left"}}>From Level</h6>
                <select class="form-select " aria-label="Default select example">

                 <option selected>Open this select menu</option>
                 <option value="1">Grade 1</option>
                 <option value="2">Grade 2</option>
                 <option value="3">Grade 3</option>
                 <option value="4">Grade 4</option>
                 <option value="5">Grade 5</option>
                 <option value="6">Grade 6 </option>
                 <option value="7">Grade 7</option>
                 <option value="8">Grade 8</option>
                 <option value="9">Grade 9</option>
                 <option value="10">Grade 10</option>
                 <option value="11">Grade 11</option>
                 <option value="12">Grade 12</option>
                </select>

                <h6 className='mt-5' style={{float:"left"}}>To Level</h6>

                <select class="form-select mt-5" aria-label="Default select example">

                 <option selected>Open this select menu</option>
                 <option value="1">Grade 1</option>
                 <option value="2">Grade 2</option>
                 <option value="3">Grade 3</option>
                 <option value="4">Grade 4</option>
                 <option value="5">Grade 5</option>
                 <option value="6">Grade 6 </option>
                 <option value="7">Grade 7</option>
                 <option value="8">Grade 8</option>
                 <option value="9">Grade 9</option>
                 <option value="10">Grade 10</option>
                 <option value="11">Grade 11</option>
                 <option value="12">Grade 12</option>
                </select>

                <button type='button' className="btn  mt-5"  style={{float:"left",backgroundColor: "rgb(173, 205, 75)",color: "white" }}>save</button>

           </div>
           </div>
        </div>
       
    </>
  )
}

export default TeachersForm