import React from 'react'

const ChangeEmail = () => {
  return (
   <>
    <section class="py-5">
      <div class="container">
        <div class="Change_email_page">
          <h3>Change email</h3>
          <div class="mt-5">
            <label for="exampleInputEmail1" class="form-label">Old email</label>
            <input type="email" class="form-control Old_email" id="exampleInputEmail1" aria-describedby="emailHelp" readonly value="hogode@mailinator.com" />
          </div>
          <div class="mt-4">
            <label for="exampleInputEmail1" class="form-label">New email</label>
            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
          </div>
          <button>Change Email</button>
        </div>
      </div>
    </section>
   
   </>
  )
}

export default ChangeEmail