import React from 'react'
import { useEffect } from 'react';
import { NavLink } from 'react-router-dom'
import $ from 'jquery';


const LoginPage = () => {
  useEffect(() => {
   $(document).ready(function () {
     $("#show").click(function () {
       $(".demone").hide();
       $(".demotwo").show();

       $("#show").addClass("loginactive");
       $("#hide").removeClass("loginactive");
      });
     $("#hide").click(function () {
       $(".demone").show();
       $(".demotwo").hide();
       $("#hide").addClass("loginactive");
       $("#show").removeClass("loginactive");
      });
    });

  }, []);


  return (
    <>
     <section class="py-5">
    <div class="container">
      <div class="Login_teacher_profile_pop text-center">
        <h3>
          <span> Login </span>
          <span>Join</span>
        </h3>
        <a href="#">
           {/* <i class="fa-brands fa-google fa-lg"></i>  */}
          <img width="57px"  src="teacher-mk/image8-2-removebg-preview.png" alt="Google-icon"/>
          <span> Sign in with Google</span>
        </a>
        <br />
        <p class="my-4">or</p>
        <div class="join_login_btn d-flex gap-3 justify-content-center">
          <a id="hide" class="loginactive"> Login</a>
          <a id="show"> Join</a>
        </div>
      </div>
      <div class="d-flex justify-content-center my-5">
        {/* ============= login page start==============  */}
        <form class="demone">
          <label for="Email">Email ID</label><br />
          <input type="email" name="login-email" /><br />
          <label for="Passwor">Password</label><br />
          <input type="password" name="login-password" />
          <div class="d-flex align-items-baseline justify-content-between">
            {/* <button>Login</button> */}
            <NavLink to="/teacher-dashboard"><button>Login</button></NavLink> 
            <div class="text-center">
              <a href="#">Forgot your password?</a>
            </div>
          </div>
        </form>

        {/* ============= join page start============== */}
        <form class="demotwo" style={{display: "none"}} >
          <label for="Name">Name</label><br />
          <input type="text" name="login-name" /><br />
          <label for="Email">Email ID</label><br />
          <input type="email" name="login-email" /><br />
          <label for="Passwor">Password</label><br />
          <input type="password" name="login-password" />
          <label for="profile">I am a</label><br />
          <select>
             {/* <option>-- Select --</option>  */}
            <option>Teacher</option>
            <option>Student/Parent</option>
            <option>Store user (buy/sell items)</option>
          </select>
          <div class="d-flex align-items-baseline gap-3 my-3"><input type="checkbox" />This is my first account.
            </div>
         <NavLink to="/verify"><button>Register</button></NavLink> 
        </form>
      </div>
    </div>

  </section>
    
    
    </>
  )
}

export default LoginPage