import React from "react";
import { NavLink } from "react-router-dom";

function Tutorform() {
  return (
    <>
 
 <div className="container-fluid" id="grad1">
        <div className="row justify-content-center mt-0">
          <div className="col-11 col-sm-9 text-center p-0 mt-3 mb-2">
            <div className="card px-0 pt-4 pb-0 mt-3 mb-3">
              <h1><strong>Request a tutor</strong></h1>
             
              <div className="row">
                <div className="col-md-12 mx-0">
                  <form id="msform">
                    {/* progressbar */}
                    {/* <ul id="progressbar">
                      <li className="active" id="account"><strong>Your basic info</strong></li>
                      <li id="personal"><strong>Address </strong></li>
                      <li id="payment"><strong>Subjects you Teach</strong></li>
                      <li id="confirm"><strong>Education</strong></li>
                      <li id="confirm1"><strong>Professional</strong></li>
                      
                      
                    </ul> */}
                    {/* fieldsets */}
                    <legend><span className="number">1</span> Your basic info</legend>
                    <fieldset>
                      <div className="form-card">
                    

                        <label htmlFor="name">Email</label>
          <input type="text" id="name" name="user_name" />
          <label htmlFor="name">Name</label>
          <input type="text" id="name" name="user_name" />
          <label htmlFor="name">Location</label>
          <input type="text" id="name" name="user_name" />
          <label htmlFor="name">Phone</label>
          <input type="text" id="name" name="user_name" />
          <label htmlFor="name">Details of your requirement</label>
          <textarea name="" id="" cols="30" rows="10"></textarea>
          <label htmlFor="name">Subjects</label>
          <input type="text" id="name" name="user_name" />
          <label htmlFor="name">Level:</label>
          <select id="name" name="user_name">
    <option value="option1">---Select Level---</option>
    <option value="option2">Male</option>
    <option value="option3">Female</option>
</select>
<label htmlFor="name">I want:</label>
          <select id="name" name="user_name">
    <option value="option1">----Select----</option>
    <option value="option2">Male</option>
    <option value="option3">Female</option>
</select>
          <label htmlFor="name">Meeting options:</label>
          <input type="date" id="name" name="user_name" />
          <label htmlFor="name">Budget:</label>
          <input type="date" id="name" name="user_name" />

<label htmlFor="name">Gender Preference:</label>
          <select id="name" name="user_name">
    <option value="option1">----Select----</option>
    <option value="option2">Male</option>
    <option value="option3">Female</option>
</select>
<label htmlFor="name">Tutors wanted
:</label>
          <select id="name" name="user_name">
    <option value="option1">----Select----</option>
    <option value="option2">Male</option>
    <option value="option3">Female</option>
</select>
<label htmlFor="name">I need someone</label>
          <select id="name" name="user_name">
    <option value="option1">----Select----</option>
    <option value="option2">Male</option>
    <option value="option3">Female</option>
</select>
<label htmlFor="mail">I can communicate with the tutor in (languages)</label>
          <input type="text" id="mail" name="user_email" />
          <label htmlFor="mail">Upload files:</label>
          <input type="file" id="mail" name="user_email" />
                      </div>
                      <button type="submit">Submit</button>
                    </fieldset>
                   
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
   </>
  );
}

export default Tutorform;