import React from 'react'

const ReferandEarn = () => {
  return (
   <>
     <section class="pt-3 pb-3">
      <div class="container">
        <div class="d-flex justify-content-center my-5">
          <div class="Refer_earn_page">
            <h3><i class="fa-solid fa-coins me-3" style={{color: "#adcd4b"}}></i>Refer and earn coins</h3>
            <ul>
              <li>When you refer someone and they buy coins, you get 10% of the coins they buy.</li>
              <li>When a tutor joins, you get referral coins according to the referred <a href="#">tutor's country.</a></li>
            </ul>

            <h4>Share your referral link</h4>
            <div class="d-flex align-items-baseline">
              <input class="form-control" id="text" type="text" readonly value="https://www.teach.com/?r=883O" role="link" />

              <button id="copy" tooltip="Copy to clipboard">Copy</button>
            </div>
            <h4>Referral Coins for Countries</h4>
            <p>When a tutor joins using your referral link, you get coins based on their location.</p>
            <label for="search"> <i class="fa-solid fa-magnifying-glass me-3" style={{color:" #adcd4b"}}></i>Search Country</label>

            <input class="form-control" type="search" placeholder="Search......" />

            <div class="table_box mt-3">
              <table class="table table-striped table-bordered display" id="example">
                <thead>
                  <tr>
                    <th style={{width: "65%"}}>Country</th>
                    <th>Bonus Coins</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Afghanistan</td>
                    <td>7</td>
                  </tr>
                  <tr>
                    <td>Åland Islands</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>Albania</td>
                    <td>46</td>
                  </tr>
                  <tr>
                    <td>Algeria</td>
                    <td>55</td>
                  </tr>
                  <tr>
                    <td>American Samoa</td>
                    <td>48</td>
                  </tr>
                  <tr>
                    <td>Andorra</td>
                    <td>183</td>
                  </tr>
                  <tr>
                    <td>Angola</td>
                    <td>25</td>
                  </tr>
                  <tr>
                    <td>Anguilla</td>
                    <td>108</td>
                  </tr>
                  <tr>
                    <td>Antarctica</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>Antigua and Barbuda</td>
                    <td>96</td>
                  </tr>
                  <tr>
                    <td>Argentina</td>
                    <td>76</td>
                  </tr>
                  <tr>
                    <td>Armenia</td>
                    <td>33</td>
                  </tr>
                  <tr>
                    <td>Australia</td>
                    <td>183</td>
                  </tr>
                  <tr>
                    <td>Austria</td>
                    <td>180</td>
                  </tr>
                  <tr>
                    <td>Azerbaijan</td>
                    <td>64</td>
                  </tr>
                  <tr>
                    <td>Bahrain</td>
                    <td>190</td>
                  </tr>
                  <tr>
                    <td>Bangladesh</td>
                    <td>15</td>
                  </tr>
                  <tr>
                    <td>Barbados</td>
                    <td>64</td>
                  </tr>
                  <tr>
                    <td>Belarus</td>
                    <td>68</td>
                  </tr>
                  <tr>
                    <td>Belgium</td>
                    <td>169</td>
                  </tr>
                  <tr>
                    <td>Belize</td>
                    <td>30</td>
                  </tr>
                  <tr>
                    <td>Benin</td>
                    <td>8</td>
                  </tr>
                  <tr>
                    <td>Bermuda</td>
                    <td>314</td>
                  </tr>
                  <tr>
                    <td>Bhutan</td>
                    <td>32</td>
                  </tr>
                  <tr>
                    <td>Bolivia</td>
                    <td>27</td>
                  </tr>
                  <tr>
                    <td>Bosnia and Herzegovina</td>
                    <td>42</td>
                  </tr>
                  <tr>
                    <td>Botswana</td>
                    <td>66</td>
                  </tr>
                  <tr>
                    <td>Brazil</td>
                    <td>57</td>
                  </tr>
                  <tr>
                    <td>British Virgin Islands</td>
                    <td>155</td>
                  </tr>
                  <tr>
                    <td>Brunei Darussalam</td>
                    <td>281</td>
                  </tr>
                  <tr>
                    <td>Bulgaria</td>
                    <td>79</td>
                  </tr>
                  <tr>
                    <td>Burkina Faso</td>
                    <td>7</td>
                  </tr>
                  <tr>
                    <td>Burundi</td>
                    <td>3</td>
                  </tr>
                  <tr>
                    <td>Cambodia</td>
                    <td>15</td>
                  </tr>
                  <tr>
                    <td>Cameroon</td>
                    <td>12</td>
                  </tr>
                  <tr>
                    <td>Canada</td>
                    <td>176</td>
                  </tr>
                  <tr>
                    <td>Cape Verde</td>
                    <td>25</td>
                  </tr>
                  <tr>
                    <td>Cayman Islands</td>
                    <td>160</td>
                  </tr>

                  {/* <!-- copy --> */}

                  <tr role="row" class="even">
                    <td>Cayman Islands</td>
                    <td>160</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Central African Republic</td>
                    <td>3</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Chad</td>
                    <td>9</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Channel Islands</td>
                    <td>0</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Chile</td>
                    <td>90</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>China</td>
                    <td>61</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Colombia</td>
                    <td>53</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Comoros</td>
                    <td>6</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Congo, Dem. Rep.</td>
                    <td>3</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Congo, Rep.</td>
                    <td>25</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Costa Rica</td>
                    <td>63</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Cote d'Ivoire</td>
                    <td>14</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Croatia</td>
                    <td>88</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Cuba</td>
                    <td>44</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Curacao</td>
                    <td>55</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Cyprus</td>
                    <td>134</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Czech Republic</td>
                    <td>129</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Denmark</td>
                    <td>181</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Djibouti</td>
                    <td>13</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Dominica</td>
                    <td>44</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Dominican Republic</td>
                    <td>62</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Ecuador</td>
                    <td>41</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Egypt</td>
                    <td>48</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>El Salvador</td>
                    <td>33</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Equatorial Guinea</td>
                    <td>128</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Eritrea</td>
                    <td>5</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Estonia</td>
                    {/* <!-- <td class="hidden">EST</td> --> */}
                    <td>115</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Ethiopia</td>
                    {/* <!-- <td class="hidden">ETH</td> --> */}
                    <td>8</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Faroe Islands</td>
                    {/* <!-- <td class="hidden">FRO</td> --> */}
                    <td>146</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Fiji</td>
                    {/* <!-- <td class="hidden">FJI</td> --> */}
                    <td>36</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Finland</td>
                    {/* <!-- <td class="hidden">FIN</td> --> */}
                    <td>161</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>France</td>
                    {/* <!-- <td class="hidden">FRA</td> --> */}
                    <td>160</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>French Guiana</td>
                    {/* <!-- <td class="hidden"></td> --> */}
                    <td>0</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>French Polynesia</td>
                    {/* <!-- <td class="hidden">PYF</td> --> */}
                    <td>62</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Gabon</td>
                    {/* <!-- <td class="hidden">GAB</td> --> */}
                    <td>71</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Georgia</td>
                    {/* <!-- <td class="hidden">GEO</td> --> */}
                    <td>39</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Germany</td>
                    {/* <!-- <td class="hidden">DEU</td> --> */}
                    <td>184</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Ghana</td>
                    {/* <!-- <td class="hidden">GHA</td> --> */}
                    <td>17</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Gibraltar</td>
                    {/* <!-- <td class="hidden">GIB</td> --> */}
                    <td>226</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Greece</td>
                    {/* <!-- <td class="hidden">GRC</td> --> */}
                    <td>102</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Greenland</td>
                    {/* <!-- <td class="hidden">GRL</td> --> */}
                    <td>138</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Grenada</td>
                    {/* <!-- <td class="hidden">GRD</td> --> */}
                    <td>54</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Guadeloupe</td>
                    {/* <!-- <td class="hidden"></td> --> */}
                    <td>0</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Guam</td>
                    {/* <!-- <td class="hidden">GUM</td> --> */}
                    <td>112</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Guatemala</td>
                    {/* <!-- <td class="hidden">GTM</td> --> */}
                    <td>30</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Guinea</td>
                    {/* <!-- <td class="hidden">GIN</td> --> */}
                    <td>7</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Guinea-Bissau</td>
                    {/* <!-- <td class="hidden">GNB</td> --> */}
                    <td>7</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Guyana</td>
                    {/* <!-- <td class="hidden">GUY</td> --> */}
                    <td>30</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Haiti</td>
                    {/* <!-- <td class="hidden">HTI</td> --> */}
                    <td>7</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Honduras</td>
                    {/* <!-- <td class="hidden">HND</td> --> */}
                    <td>20</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Hong Kong</td>
                    {/* <!-- <td class="hidden">HKG</td> --> */}
                    <td>223</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Hungary</td>
                    {/* <!-- <td class="hidden">HUN</td> --> */}
                    <td>106</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Iceland</td>
                    {/* <!-- <td class="hidden">ISL</td> --> */}
                    <td>191</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>India</td>
                    {/* <!-- <td class="hidden">IND</td> --> */}
                    <td>26</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Indonesia</td>
                    {/* <!-- <td class="hidden">IDN</td> --> */}
                    <td>45</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Iran</td>
                    {/* <!-- <td class="hidden">IRN</td> --> */}
                    <td>73</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Iraq</td>
                    {/* <!-- <td class="hidden">IRQ</td> --> */}
                    <td>62</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Ireland</td>
                    {/* <!-- <td class="hidden">IRL</td> --> */}
                    <td>266</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Isle of Man</td>
                    {/* <!-- <td class="hidden">IMN</td> --> */}
                    <td>310</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Israel</td>
                    {/* <!-- <td class="hidden">ISR</td> --> */}
                    <td>132</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Italy</td>
                    {/* <!-- <td class="hidden">ITA</td> --> */}
                    <td>139</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Jamaica</td>
                    {/* <!-- <td class="hidden">JAM</td> --> */}
                    <td>34</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Japan</td>
                    {/* <!-- <td class="hidden">JPN</td> --> */}
                    <td>156</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Jersey</td>
                    {/* <!-- <td class="hidden">JEY</td> --> */}
                    <td>181</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Jordan</td>
                    {/* <!-- <td class="hidden">JOR</td> --> */}
                    <td>46</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Kazakhstan</td>
                    {/* <!-- <td class="hidden">KAZ</td> --> */}
                    <td>95</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Kenya</td>
                    {/* <!-- <td class="hidden">KEN</td> --> */}
                    <td>0</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Kiribati</td>
                    {/* <!-- <td class="hidden">KIR</td> --> */}
                    <td>7</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Kosovo</td>
                    {/* <!-- <td class="hidden">KSV</td> --> */}
                    <td>38</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Kuwait</td>
                    {/* <!-- <td class="hidden">KWT</td> --> */}
                    <td>255</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Kyrgyz Republic</td>
                    {/* <!-- <td class="hidden">KGZ</td> --> */}
                    <td>14</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Lao PDR</td>
                    {/* <!-- <td class="hidden">LAO</td> --> */}
                    <td>27</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Latvia</td>
                    {/* <!-- <td class="hidden">LVA</td> --> */}
                    <td>100</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Lebanon</td>
                    {/* <!-- <td class="hidden">LBN</td> --> */}
                    <td>71</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Lesotho</td>
                    {/* <!-- <td class="hidden">LSO</td> --> */}
                    <td>14</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Liberia</td>
                    {/* <!-- <td class="hidden">LBR</td> --> */}
                    <td>3</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Libya</td>
                    {/* <!-- <td class="hidden">LBY</td> --> */}
                    <td>36</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Liechtenstein</td>
                    {/* <!-- <td class="hidden">LIE</td> --> */}
                    <td>509</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Lithuania</td>
                    {/* <!-- <td class="hidden">LTU</td> --> */}
                    <td>117</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Luxembourg</td>
                    {/* <!-- <td class="hidden">LUX</td> --> */}
                    <td>399</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Macau</td>
                    {/* <!-- <td class="hidden">MAC</td> --> */}
                    <td>419</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Macedonia</td>
                    {/* <!-- <td class="hidden">MKD</td> --> */}
                    <td>56</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Madagascar</td>
                    {/* <!-- <td class="hidden">MDG</td> --> */}
                    <td>6</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Malawi</td>
                    {/* <!-- <td class="hidden">MWI</td> --> */}
                    <td>4</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Malaysia</td>
                    {/* <!-- <td class="hidden">MYS</td> --> */}
                    <td>106</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Maldives</td>
                    {/* <!-- <td class="hidden">MDV</td> --> */}
                    <td>70</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Mali</td>
                    {/* <!-- <td class="hidden">MLI</td> --> */}
                    <td>8</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Malta</td>
                    {/* <!-- <td class="hidden">MLT</td> --> */}
                    <td>155</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Marshall Islands</td>
                    {/* <!-- <td class="hidden">MHL</td> --> */}
                    <td>12</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Martinique</td>
                    {/* <!-- <td class="hidden"></td> --> */}
                    <td>0</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Mauritania</td>
                    {/* <!-- <td class="hidden">MRT</td> --> */}
                    <td>16</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Mauritius</td>
                    {/* <!-- <td class="hidden">MUS</td> --> */}
                    <td>79</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Mexico</td>
                    {/* <!-- <td class="hidden">MEX</td> --> */}
                    <td>71</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Micronesia</td>
                    {/* <!-- <td class="hidden">FSM</td> --> */}
                    <td>12</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Moldova</td>
                    {/* <!-- <td class="hidden">MDA</td> --> */}
                    <td>21</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Monaco</td>
                    {/* <!-- <td class="hidden">MCO</td> --> */}
                    <td>423</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Mongolia</td>
                    {/* <!-- <td class="hidden">MNG</td> --> */}
                    <td>46</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Montenegro</td>
                    {/* <!-- <td class="hidden">MNE</td> --> */}
                    <td>64</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Morocco</td>
                    {/* <!-- <td class="hidden">MAR</td> --> */}
                    <td>31</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Mozambique</td>
                    {/* <!-- <td class="hidden">MOZ</td> --> */}
                    <td>5</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Myanmar</td>
                    {/* <!-- <td class="hidden">MMR</td> --> */}
                    <td>23</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Namibia</td>
                    {/* <!-- <td class="hidden">NAM</td> --> */}
                    <td>42</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Nauru</td>
                    {/* <!-- <td class="hidden">NRU</td> --> */}
                    <td>45</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Nepal</td>
                    {/* <!-- <td class="hidden">NPL</td> --> */}
                    <td>10</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Netherlands</td>
                    {/* <!-- <td class="hidden">NLD</td> --> */}
                    <td>196</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>New Caledonia</td>
                    {/* <!-- <td class="hidden">NCL</td> --> */}
                    <td>114</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>New Zealand</td>
                    {/* <!-- <td class="hidden">NZL</td> --> */}
                    <td>141</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Nicaragua</td>
                    {/* <!-- <td class="hidden">NIC</td> --> */}
                    <td>21</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Niger</td>
                    {/* <!-- <td class="hidden">NER</td> --> */}
                    <td>4</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Nigeria</td>
                    {/* <!-- <td class="hidden">NGA</td> --> */}
                    <td>0</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>North Korea</td>
                    {/* <!-- <td class="hidden">PRK</td> --> */}
                    <td>6</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Northern Mariana Islands</td>
                    {/* <!-- <td class="hidden">MNP</td> --> */}
                    <td>49</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Norway</td>
                    {/* <!-- <td class="hidden">NOR</td> --> */}
                    <td>258</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Oman</td>
                    {/* <!-- <td class="hidden">OMN</td> --> */}
                    <td>166</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Pakistan</td>
                    {/* <!-- <td class="hidden">PAK</td> --> */}
                    <td>0</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Palau</td>
                    {/* <!-- <td class="hidden">PLW</td> --> */}
                    <td>61</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Palestine</td>
                    {/* <!-- <td class="hidden"></td> --> */}
                    <td>3</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Panama</td>
                    {/* <!-- <td class="hidden">PAN</td> --> */}
                    <td>89</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Papua New Guinea</td>
                    {/* <!-- <td class="hidden">PNG</td> --> */}
                    <td>14</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Paraguay</td>
                    {/* <!-- <td class="hidden">PRY</td> --> */}
                    <td>36</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Peru</td>
                    {/* <!-- <td class="hidden">PER</td> --> */}
                    <td>49</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Philippines</td>
                    {/* <!-- <td class="hidden">PHL</td> --> */}
                    <td>30</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Poland</td>
                    {/* <!-- <td class="hidden">POL</td> --> */}
                    <td>107</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Portugal</td>
                    {/* <!-- <td class="hidden">PRT</td> --> */}
                    <td>111</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Puerto Rico</td>
                    {/* <!-- <td class="hidden">PRI</td> --> */}
                    <td>139</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Qatar</td>
                    {/* <!-- <td class="hidden">QAT</td> --> */}
                    <td>200</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Reunion</td>
                    {/* <!-- <td class="hidden"></td> --> */}
                    <td>0</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Romania</td>
                    {/* <!-- <td class="hidden">ROU</td> --> */}
                    <td>88</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Russian Federation</td>
                    {/* <!-- <td class="hidden">RUS</td> --> */}
                    <td>102</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Rwanda</td>
                    {/* <!-- <td class="hidden">RWA</td> --> */}
                    <td>8</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Saint Barthélemy</td>
                    {/* <!-- <td class="hidden"></td> --> */}
                    <td>0</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Saint Pierre and Miquelon</td>
                    {/* <!-- <td class="hidden"></td> --> */}
                    <td>128</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Samoa</td>
                    {/* <!-- <td class="hidden">WSM</td> --> */}
                    <td>21</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>San Marino</td>
                    {/* <!-- <td class="hidden">SMR</td> --> */}
                    <td>218</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Sao Tome and Principe</td>
                    {/* <!-- <td class="hidden">STP</td> --> */}
                    <td>12</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Saudi Arabia</td>
                    {/* <!-- <td class="hidden">SAU</td> --> */}
                    <td>40</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Senegal</td>
                    {/* <!-- <td class="hidden">SEN</td> --> */}
                    <td>10</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Serbia</td>
                    {/* <!-- <td class="hidden">SRB</td> --> */}
                    <td>56</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Seychelles</td>
                    {/* <!-- <td class="hidden">SYC</td> --> */}
                    <td>106</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Sierra Leone</td>
                    {/* <!-- <td class="hidden">SLE</td> --> */}
                    <td>7</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Singapore</td>
                    {/* <!-- <td class="hidden">SGP</td> --> */}
                    <td>331</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Sint Maarten (Dutch part)</td>
                    {/* <!-- <td class="hidden">SXM</td> --> */}
                    <td>244</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Slovakia</td>
                    {/* <!-- <td class="hidden">SVK</td> --> */}
                    <td>120</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Slovenia</td>
                    {/* <!-- <td class="hidden">SVN</td> --> */}
                    <td>125</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Solomon Islands</td>
                    {/* <!-- <td class="hidden">SLB</td> --> */}
                    <td>8</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Somalia</td>
                    {/* <!-- <td class="hidden">SOM</td> --> */}
                    <td>0</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>South Africa</td>
                    {/* <!-- <td class="hidden">ZAF</td> --> */}
                    <td>49</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>South Korea</td>
                    {/* <!-- <td class="hidden">KOR</td> --> */}
                    <td>144</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>South Sudan</td>
                    {/* <!-- <td class="hidden">SSD</td> --> */}
                    <td>5</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Spain</td>
                    {/* <!-- <td class="hidden">ESP</td> --> */}
                    <td>140</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Sri Lanka</td>
                    {/* <!-- <td class="hidden">LKA</td> --> */}
                    <td>48</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>St. Kitts and Nevis</td>
                    {/* <!-- <td class="hidden">KNA</td> --> */}
                    <td>98</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>St. Lucia</td>
                    {/* <!-- <td class="hidden">LCA</td> --> */}
                    <td>49</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>St. Martin (French part)</td>
                    {/* <!-- <td class="hidden">MAF</td> --> */}
                    <td>71</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>St. Vincent and the Grenadines</td>
                    {/* <!-- <td class="hidden">VCT</td> --> */}
                    <td>42</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Sudan</td>
                    {/* <!-- <td class="hidden">SDN</td> --> */}
                    <td>17</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Suriname</td>
                    {/* <!-- <td class="hidden">SUR</td> --> */}
                    <td>51</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Svalbard and Jan Mayen</td>
                    {/* <!-- <td class="hidden">SJM</td> --> */}
                    <td>489</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Swaziland</td>
                    {/* <!-- <td class="hidden">SWZ</td> --> */}
                    <td>36</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Sweden</td>
                    {/* <!-- <td class="hidden">SWE</td> --> */}
                    <td>188</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Switzerland</td>
                    {/* <!-- <td class="hidden">CHE</td> --> */}
                    <td>225</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Syrian Arab Republic</td>
                    {/* <!-- <td class="hidden">SYR</td> --> */}
                    <td>11</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Taiwan</td>
                    {/* <!-- <td class="hidden">TWN</td> --> */}
                    <td>182</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Tajikistan</td>
                    {/* <!-- <td class="hidden">TJK</td> --> */}
                    <td>11</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Tanzania</td>
                    {/* <!-- <td class="hidden">TZA</td> --> */}
                    <td>12</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Thailand</td>
                    {/* <!-- <td class="hidden">THA</td> --> */}
                    <td>65</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>The Bahamas</td>
                    {/* <!-- <td class="hidden">BHS</td> --> */}
                    <td>92</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>The Gambia</td>
                    {/* <!-- <td class="hidden">GMB</td> --> */}
                    <td>6</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Timor-Leste</td>
                    {/* <!-- <td class="hidden">TLS</td> --> */}
                    <td>18</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Togo</td>
                    {/* <!-- <td class="hidden">TGO</td> --> */}
                    <td>6</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Tokelau</td>
                    {/* <!-- <td class="hidden">TKL</td> --> */}
                    <td>0</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Tonga</td>
                    {/* <!-- <td class="hidden">TON</td> --> */}
                    <td>20</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Trinidad and Tobago</td>
                    {/* <!-- <td class="hidden">TTO</td> --> */}
                    <td>114</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Tunisia</td>
                    {/* <!-- <td class="hidden">TUN</td> --> */}
                    <td>44</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Turkey</td>
                    {/* <!-- <td class="hidden">TUR</td> --> */}
                    <td>97</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Turkmenistan</td>
                    {/* <!-- <td class="hidden">TKM</td> --> */}
                    <td>68</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Turks and Caicos Islands</td>
                    {/* <!-- <td class="hidden">TCA</td> --> */}
                    <td>106</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Tuvalu</td>
                    {/* <!-- <td class="hidden">TUV</td> --> */}
                    <td>14</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Uganda</td>
                    {/* <!-- <td class="hidden">UGA</td> --> */}
                    <td>9</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Ukraine</td>
                    {/* <!-- <td class="hidden">UKR</td> --> */}
                    <td>32</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>United Arab Emirates</td>
                    {/* <!-- <td class="hidden">ARE</td> --> */}
                    <td>50</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>United Kingdom</td>
                    {/* <!-- <td class="hidden">GBR</td> --> */}
                    <td>160</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>United States</td>
                    {/* <!-- <td class="hidden">USA</td> --> */}
                    <td>218</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>United States Minor Outlying Islands</td>
                    {/* <!-- <td class="hidden"></td> --> */}
                    <td>0</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Uruguay</td>
                    {/* <!-- <td class="hidden">URY</td> --> */}
                    <td>82</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Uzbekistan</td>
                    {/* <!-- <td class="hidden">UZB</td> --> */}
                    <td>26</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Vanuatu</td>
                    {/* <!-- <td class="hidden">VUT</td> --> */}
                    <td>10</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Vatican City</td>
                    {/* <!-- <td class="hidden"></td> --> */}
                    <td>0</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Venezuela</td>
                    {/* <!-- <td class="hidden">VEN</td> --> */}
                    <td>45</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Vietnam</td>
                    {/* <!-- <td class="hidden">VNM</td> --> */}
                    <td>25</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Virgin Islands (U.S.)</td>
                    {/* <!-- <td class="hidden">VIR</td> --> */}
                    <td>132</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Wallis and Futuna</td>
                    {/* <!-- <td class="hidden"></td> --> */}
                    <td>14</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Western Sahara</td>
                    {/* <!-- <td class="hidden"></td> --> */}
                    <td>9</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Yemen, Rep.</td>
                    {/* <!-- <td class="hidden">YEM</td> --> */}
                    <td>8</td>
                  </tr>
                  <tr role="row" class="even">
                    <td>Zambia</td>
                    {/* <!-- <td class="hidden">ZMB</td> --> */}
                    <td>15</td>
                  </tr>
                  <tr role="row" class="odd">
                    <td>Zimbabwe</td>
                    {/* <!-- <td class="hidden">ZWE</td> --> */}
                    <td>8</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
   
   
   </>
  )
}

export default ReferandEarn