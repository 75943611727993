import React from 'react';
import { NavLink } from 'react-router-dom';

const TeacherProfileDashboard = () => {
  return (
<>
<div className='container overflow-hidden'>
    
        <div className='row border mt-5 '>
            <div className='col-10' style={{padding:"0"}}>
                <div className='w-100 icon-full-div'>
                  <div className='bg-clr-green h-100 icon-div position-relative'>
                   <div className='skew-div position-absolute h-100 position-absolute'></div>
                   <img src="teacher-mk/warning.png" alt="not loading" className='icon-img img-fluid ma'/>

                     </div>
                  <p className='verification-text' style={{paddingTop:"0",marginLeft:"20px"}}>verification skipped for +1 7419054673</p>
                </div>
                
            </div>
            <div className='col-2'>
                  <i class="fa-solid fa-delete-left" style={{marginTop:"13px",marginTop: "13px",
    float: "right"}}></i>
            </div>
        </div>
      <div className='row overflow-hidden'>
         <div className='col-xl-8 mt-5'>
              <div className='d-flex ma upper-div borde overflow-hidden'>
           
              <div className='down-box border'>
                <input type="file" style={{visibility:'hidden'} } id="a"/>
                <label for="a">
                <i class="fa-solid fa-file txt-clr file-icon"></i>
                 <p>Upload Photos</p> </label>
              </div>
              <div className='down-box border'>
              <i class="fa-solid fa-mobile-screen-button" style={{fontSize:"30px",color:"rgb(173, 205, 75)",padding:"0" }}></i>
                <p>Verify Mobile</p>
              </div>

              </div>

              <div className="bottom-full-div mt-5 ">
              <div className='d-first  '>
             
              <div className='down-box border'>
              <i class="fa-solid fa-thumbs-up" style={{fontSize:"30px",color:"rgb(173, 205, 75)",padding:"0" }}></i>
                <p>Get Reviews</p>
              </div>
              <div className='down-box border '>
              <i class="fa-solid fa-wallet "  style={{fontSize:"30px",color:"rgb(173, 205, 75)",padding:"0" }}></i>
                <p>Refer and Earn</p>
              </div>
              </div>
             
              <div className='d-second '>
              <div className='down-box border mrgn'>
              <i class="fa-solid fa-bullhorn" style={{fontSize:"30px",color:"rgb(173, 205, 75)",padding:"0" }}></i>
                <p>Promote Yourself</p>
              </div>
              <div className='down-box border '>
              <i class="fa-solid fa-users" style={{fontSize:"30px",color:"rgb(173, 205, 75)",padding:"0" }}></i>
                <p>Invite Friends</p>
              </div>
              
  
                </div>
                </div>
            
          </div>

          <div className='col-xl-4'>
            <div className='mt-5 side-col'>
              <NavLink to="/teacher-profile">
              <div className='border wid my-profile-full-box ma'>
              <i class="fa-solid fa-eye text-white mt-3" style={{fontSize:"20px"}}></i>
                <p style={{opacity:"1 !important" ,color:"white"}}>My profile</p>
              </div>
              </NavLink>
              <div className='border wid my-profile-full-box ma  coins-div'>
              <i class="fa-solid fa-wallet text-white mt-3" style={{fontSize:"20px"}}></i>
                <p style={{opacity:"1 !important" ,color:"white"}}>0 coins</p>
              </div>
              </div>
          </div>  

      </div>
</div>

</>
  )
}

export default TeacherProfileDashboard;