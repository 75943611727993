import React from 'react'
import { NavLink } from 'react-router-dom'

const VerifyEmail = () => {
  return (
   <>
   
  <section class="py-5">
    <div class="container">
      <div class="verify_email_page text-center">
        <h3>Check your inbox to verify email</h3>

        <p>We've sent an email to <b>narabhit5kwt@gmail.com</b><br />
          Follow the instructions to verify your email address</p>
        <img src="teacher-mk/372829-PBsdsdLJIB-80.jpg" alt="Email victor"/><br />
        <a href="#">I have not received mail</a>
      </div>
    </div>
  <NavLink to="/stepform"> <button type="button" className='btn  next-btn bg-clr-green'>Next</button></NavLink> 

  </section>
   
   
   
   </>
  )
}

export default VerifyEmail