// TeacherProfile.js
import React from "react";
import { NavLink } from "react-router-dom";
function TeacherProfile() {
  // Your component logic here
  return (
    <>
      <section className="Teacher_Main_section my-5">
        <div className="container p-xl-0 p-lg-0 p-md-auto">
          <div className="row">
            <div className="col-lg-4 p-0 position-relative tutor_box_img">
              <img className="teacher_Img" src="teacher-mk/closeup-senior-lecturer-with-arms-crossed.jpg" alt="Teacher-porfile" />
              <div className="social_box">
                <div className="w-50 h-100 d-inline-flex align-items-center justify-content-center gap-3 left_box_icons">
                  <i className="fa-brands fa-square-facebook"></i>
                  <i className="fa-brands fa-instagram"></i>
                  <i className="fa-brands fa-square-twitter"></i>
                </div>
                <div className="w-50 h-100 d-inline-flex align-items-center justify-content-center gap-4">
                  <i className="fa-solid fa-phone"></i>
                  <p>Reviews</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 ps-xl-5 pe-xl-0 columan">
              <div className="d-flex align-items-center gap-3">
                <div className="color_dot"></div>
                <p className="Teaching_text">Expert in teaching Science</p>
              </div>
              <h2 className="fw-bold">John Smith</h2>
              <p>
                Dear Students! <br />
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
              </p>
            </div>

            <div className="col-lg-4 ps-xl-5 mt-xl-3">
              <div className="tutor_Detail_box">
                <div className="d-flex justify-content-around">
                  <a href="#" className="Box_btn">
                    <div>
                      <i className="fa-solid fa-phone"></i> Phone
                    </div>
                  </a>

                  <a href="#" className="Box_btn">
                    <div>
                      <i className="fa fa-thin fa-envelope-open"></i>
                      Message
                    </div>
                  </a>
                </div>

                <div className="d-flex justify-content-around mt-2">
                  <a href="#" className="Box_btn">
                    <div>
                      <i className="fa-solid fa-phone"></i> Pay
                    </div>
                  </a>

                  <a href="#" className="Box_btn">
                    <div>
                      <i className="fa-solid fa-phone"></i>Review
                    </div>
                  </a>
                </div>

                <div className="tutor_details_sec mt-4 ms-2">
                  <div className="border-bottom-box">
                    <p>
                      <i className="fa fa-thin fa-location-dot"></i>
                      Johrat, Asssa, India
                    </p>
                  </div>

                  <div className="border-bottom-box">
                    <p>
                      <i className="fa fa-thin fa-plane-up"></i>
                      Can travel: No
                    </p>
                  </div>

                  <div className="border-bottom-box">
                    <p>
                      <i className="fa fa-light fa-right-to-bracket"></i>
                      Last login: 2hours ago
                    </p>
                  </div>

                  <div className="border-bottom-box">
                    <p>
                      <i className="fa-solid fa-phone"></i>
                      Registered: 3 day ago
                    </p>
                  </div>

                  <div className="border-bottom-box">
                    <p>
                      <i className="fa-solid fa-person-chalkboard"></i>
                      Total Teaching Exp: 5 years
                    </p>
                  </div>

                  <div className="border-bottom-box">
                    <p>
                      <i className="fa-brands fa-slack"></i>
                      Teaching online: Yes
                    </p>
                  </div>

                  <div className="border-bottom-box">
                    <p>
                      <i className="fa fa-thin fa-desktop"></i>
                      Online teaching Exp: 3 years
                    </p>
                  </div>

                  <div className="border-bottom-box">
                    <p>
                      <i className="fa fa-thin fa-house-chimney"></i>
                      Teaching at student’s home: Yes
                    </p>
                  </div>

                  <div className="border-bottom-box">
                    <p>
                      <i className="fa fa-duotone fa-book-open"></i>
                      Homework Help: No
                    </p>
                  </div>

                  <div className="border-bottom-box">
                    <p>
                      <i className="fa fa-light fa-venus-mars"></i>
                      Gender: Male
                    </p>
                  </div>

                  <div className="border-bottom-box">
                    <p>
                      <i className="fa fa-light fa-briefcase"></i>
                      Work as: Individual Teacher
                    </p>
                  </div>
                  <div className="border-bottom-box">
                    <p>
                      <i className="fa fa-light fa-credit-card"></i>
                      Fee: Rs.200–800/hour (US$2.41hour)
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row row-cols-4 last_fourth_box py-3">
              <div className="col sub_detail p-0">
                <div>
                  <h6>Subjects</h6>
                  <p>Science</p>
                </div>
              </div>
              <div className="col sub_detail p-0">
                <div>
                  <h6>Experience</h6>
                  <p>5 years</p>
                </div>
              </div>
              <div className="col sub_detail px-xl-2 px-lg-0 p-sm-0 ">
                <div>
                  <h6>Education</h6>
                  <p>B.tech (Jul, 2019–now) from Assam Science and Technology University</p>
                </div>
              </div>
              <div className="col sub_detail p-0">
                <div>
                  <h6>Fee details</h6>
                  <p>
                    ₹200–800/hour
                    <br /> (US$2.41–9.66/hour)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default TeacherProfile;
