import React from 'react'


const StudentRequirement = () => {
  return (
    <>

  <div className='container mt-5'>
        <div className='row sr-full-div ma   ' >
          
             
            <div className='col-10 border-l border-left-none'>
                <div className='w-100 icon-full-div position-relative'>
                  <div className='bg-clr-green h-100 icon-div  sr-icon '>
                   <div className='skew-div position-absolute h-100 position-absolute '>
                    </div>
                   <i class="fa-solid fa-check" style={{color: "white",fontWeight: "900", fontSize: "33px",textAlign: "center", marginLeft: "8px",margin:"10px"}}></i>

                     </div>
                  <p className='verification-text ' style={{paddingTop:"0",marginLeft:"20px"}}>Your requirement
has been posted. We will
email you when a tutor responds
to your
post.</p>
                </div>
                
            </div>


            
            <div className='col-2 border-r '>
                  <i class="fa-solid fa-delete-left" style={{marginTop:"13px",
    float: "right"}}></i>
            </div>

                



            <div className='col-10 border-l border-left-none mt-5' >
                <div className='w-100 icon-full-div'>
                  <div className='bg-clr-green h-100 icon-div position-relative'>
                   <div className='skew-div position-absolute h-100 position-absolute'>
                    </div>
                   <i class="fa-solid fa-check" style={{color: "white",
    fontWeight: "900",
    fontSize: "33px",
    textAlign: "center",
    marginLeft: "8px",
    margin:"10px"}}></i>

                     </div>
                  <p className='verification-text' style={{paddingTop:"0",marginLeft:"20px"}}>Verification skipped for +1 8968717187</p>
                </div>
                
            </div>
            <div className='col-2 border-r mt-5'>
                  <i class="fa-solid fa-delete-left" style={{marginTop:"13px",
    float: "right"}}></i>
            </div>

                <div className='col'>
                   <div className='d-fb mt-4'>
            <h2 class="text-start w-75">My Requirements</h2>



            <button style={{width:"171px",fontSize:"12px"}} type="button" class="btn text-white m-0 btn bg-clr-green">Post Your Study Needs</button>

            </div>
            <p className='mt-3 text-start'>Home | online Maths, Hindi, Science tutor needed in Surrey</p>
            </div>

          <p className='text-start mt-3'>I am looking for science teacher</p>

            <div className='text-start mt-4'>
              <div className='d-fb-600 '>
                <div className='d-flex'>
          <i class="fa-solid fa-car " style={{marginTop:"12px"}}></i>
          <p style={{marginLeft:"10px"}}>1km</p>
          </div>
          <div className='d-flex car-location'>
          <p className=''>CA$1 (Fixed) (.73 USD)</p>
          </div>
          <div className='d-flex '>

          <i class="fa-solid fa-location-dot  "  style={{marginTop:"12px"}}></i>
            <p className=' '>Surrey, BC, Canada</p>
            </div>
          </div>
            </div>


        <div className='text-start mt-5 ab' >

          <div className='d-flex'>
          <button type="button" className='btn text-white  btn-view-messahges bg-clr-green'>
          View Messages
          </button>
        
          <i className="fa-solid fa-pen-to-square ms-3" style={{marginTop:"12px"}}></i>
          <p className='ms-2 '>Edit</p>
          
        
          <i className="fa-solid fa-circle-xmark ms-3 " style={{marginTop:"12px"}}></i>
          <p className='ms-2'>Close</p>
         
        </div>
        </div>
            </div>

        </div>    
    
    </>
  )
}

export default StudentRequirement