import React from 'react';
import { NavLink } from 'react-router-dom';

const RequestTutor = () => {
  return (
   <>
    <section class="py-3">
      <div class="container">
        <div class="d-flex justify-content-center my-5">
          <div class="Request_tutor">
            <h3>Request a tutor</h3>
            <form class="row row-gap-4 pt-4">
              <div class="">
                <label for="exampleInputEmail1" class="form-label">Name</label>
                <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
              </div>
              <div class="">
                <label for="exampleInputEmail1" class="form-label">Email</label>
                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
              </div>
              <div class="">
                <label for="validationCustom02" class="form-label">Location</label>
                <input type="text" class="form-control" id="validationCustom02" required />
              </div>
              <div class="">
                <label for="validationCustomUsername" class="form-label">Phone</label>
                <div class="input-group has-validation">
                  <span id="inputGroupPrepend">
                    <select class="form-select" aria-label="Default select example">
                      <option selected>Open this select menu</option>
                      <option value="1">+1 Canada</option>
                      <option value="2">+91 India</option>
                      <option value="3">+12 Dubai</option>
                    </select>
                  </span>
                  <input type="tel" class="form-control" id="validationCustomUsername" aria-describedby="inputGroupPrepend" required />
                  <div class="invalid-feedback">Please choose a username.</div>
                </div>
              </div>
              <div class="my-3">
                <label for="exampleFormControlTextarea1" class="form-label">Details of your requirement</label>
                <textarea class="form-control" id="exampleFormControlTextarea1" rows="7"></textarea>
              </div>
              <NavLink to="/request-details">Continue</NavLink>
            </form>
          </div>
        </div>
      </div>
    </section></>
  )
}

export default RequestTutor