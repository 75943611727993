import React from "react";
import { useEffect } from 'react';
import { NavLink } from "react-router-dom";
import $ from "jquery"
import './sim.css';

function Stepform() {

  useEffect(() => {
    $(document).ready(function(){
    
      var current_fs, next_fs, previous_fs; //fieldsets
      var opacity;
      
      $(".next").click(function(){
          
          current_fs = $(this).parent();
          next_fs = $(this).parent().next();
          
          //Add Class Active
          $("#progressbar li").eq($("fieldset").index(next_fs)).addClass("active");
          
          //show the next fieldset
          next_fs.show(); 
          //hide the current fieldset with style
          current_fs.animate({opacity: 0}, {
              step: function(now) {
                  // for making fielset appear animation
                  opacity = 1 - now;
      
                  current_fs.css({
                      'display': 'none',
                      'position': 'relative'
                  });
                  next_fs.css({'opacity': opacity});
              }, 
              duration: 600
          });
      });
      
      $(".previous").click(function(){
          
          current_fs = $(this).parent();
          previous_fs = $(this).parent().prev();
          
          //Remove class active
          $("#progressbar li").eq($("fieldset").index(current_fs)).removeClass("active");
          
          //show the previous fieldset
          previous_fs.show();
      
          //hide the current fieldset with style
          current_fs.animate({opacity: 0}, {
              step: function(now) {
                  // for making fielset appear animation
                  opacity = 1 - now;
      
                  current_fs.css({
                      'display': 'none',
                      'position': 'relative'
                  });
                  previous_fs.css({'opacity': opacity});
              }, 
              duration: 600
          });
      });
      
      $('.radio-group .radio').click(function(){
          $(this).parent().find('.radio').removeClass('selected');
          $(this).addClass('selected');
      });
      
      $(".submit").click(function(){
         return false;
      })
          
      });
  
   }, []);
  


  return (
 
  
    <>
 <link></link>
 <div className="container-fluid sim" id="grad1">
        <div className="row justify-content-center mt-0">
          <div className="col-11 col-sm-9 text-center p-0 mt-3 mb-2">
            <div className="card px-0 pt-4 pb-0 mt-3 mb-3">
              <h2><strong>Teacher details</strong></h2>
              <p>Fill all form field to go to next step</p>
              <div className="row">
                <div className="col-md-12 mx-0">
                  <form id="msform">
                    {/* progressbar */}
                    <ul id="progressbar">
                      <li className="active" id="account"><strong>Your basic info</strong></li>
                      <li id="personal"><strong>Address </strong></li>
                      <li id="payment"><strong>Subjects you Teach</strong></li>
                      <li id="confirm"><strong>Education</strong></li>
                      <li id="confirm1"><strong>Professional</strong></li>
                      
                      
                    </ul>
                    {/* fieldsets */}
                    <fieldset>
                      <div className="form-card">
                       {/* <h2 className="fs-title">Your basic info</h2> */}
                        <label htmlFor="name">Speciality, Strength or Current role:</label>
          <input type="text" id="name" name="user_name" />

          <label htmlFor="name">Gender:</label>
          <select id="name" name="user_name">
    <option value="option1">Select Gender</option>
    <option value="option2">Male</option>
    <option value="option3">Female</option>
</select>
          <label htmlFor="name">Birth Date:</label>
          <input type="date" id="name" name="user_name" />
                      </div>
                      <input type="button" name="next" className="next action-button" defaultValue="Next Step" />
                    </fieldset>
                    <fieldset>
                      <div className="form-card">
                        {/* <h2 className="fs-title">Address (Publicly visible)</h2> */}
                        <label htmlFor="mail">Location:</label>
          <input type="text" id="mail" name="user_email" />

          <label htmlFor="mail">Postal code:</label>
          <input type="text" id="mail" name="user_email" />

          <label htmlFor="mail">Phone:</label>
          <input type="text" id="mail" name="user_email" />
                      </div>
                      <input type="button" name="previous" className="previous action-button-previous" defaultValue="Previous" />
                      <input type="button" name="next" className="next action-button" defaultValue="Next Step" />
                    </fieldset>
                    <fieldset>
                      <div className="form-card">
                        {/* <h2 className="fs-title">Payment Information</h2> */}
                        <label htmlFor="mail">Subject (one at a time):</label>
          <input type="text" id="mail" name="user_email" />
          <label htmlFor="mail">From level:</label>
          <input type="text" id="mail" name="user_email" />
          <label htmlFor="mail">To level:</label>
          <input type="text" id="mail" name="user_email" />
                      </div>
                      <input type="button" name="previous" className="previous action-button-previous" defaultValue="Previous" />
                      <input type="button" name="make_payment" className="next action-button" defaultValue="Confirm" />
                    </fieldset>
                    <fieldset>
                      <div className="form-card">
                      <label htmlFor="mail">Institution name with city:</label>
          <input type="text" id="mail" name="user_email" />
          <label htmlFor="mail">Degree type:</label>
          <input type="text" id="mail" name="user_email" />
        
          <label htmlFor="mail">Start Date:</label>
          <input type="date" id="name" name="user_name" />
          <label htmlFor="mail">End Date (Leave blank if not complete):</label>
          <input type="date" id="name" name="user_name" />
        
          <label htmlFor="mail">Association:</label>
          <input type="text" id="mail" name="user_email" />
        
          <label htmlFor="mail">Speciality (optional):</label>
          <input type="text" id="mail" name="user_email" />
        
          <label htmlFor="mail">Score (optional):</label>
          <input type="text" id="mail" name="user_email" />




          <label htmlFor="password">Details of your requirement:</label>
          <textarea></textarea>
          <label>Suggestion Subjects:</label>
          <input type="text" id="name" name="user_name" />
                      </div>
                      <input type="button" name="previous" className="previous action-button-previous" defaultValue="Previous" />
                      <input type="button" name="next" className="next action-button" defaultValue="confirm" />
                    
                    </fieldset>
                    <fieldset>
                      <div className="form-card">
                      <label htmlFor="mail">Organization name with city:</label>
          <input type="text" id="mail" name="user_email" />
          <label htmlFor="mail">Designation:</label>
          <input type="text" id="mail" name="user_email" />
        
          <label htmlFor="mail">Start Date:</label>
          <input type="date" id="name" name="user_name" />
          <label htmlFor="mail">End Date (Leave blank if not complete):</label>
          <input type="date" id="name" name="user_name" />
        
          <label htmlFor="mail">Job Description:</label>
          <textarea name="" id="" cols="30" rows="10"></textarea>

                      </div>
                      <input type="button" name="previous" className="previous action-button-previous" defaultValue="Previous" />
                      <NavLink to="/teacher-submit">
                        <input type="button" name="next" className="next action-button" defaultValue="Submit" />
                        </NavLink>
                    
                    </fieldset>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
   </>
  );
}

export default Stepform;