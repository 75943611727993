import React from 'react'

const Phone_verification = () => {
  return (
    <>
         <div className="profile">
        <div className="container- content-">
          <div className="row-" style={{maxWidth: '900px', margin: '0 auto', paddingRight: '15px', paddingLeft: '15px', textAlign: 'center'}}>
            {/* <div class="col-lg-6 col-lg-push-3 col-md-8 col-md-push-2 col-sm-10 col-sm-push-1"> */}
            <div className="display-inline-block text-left">
              {/* Begin Content */}
              {/* msg will be static and it will not hide */}
              {/* msg will be static and it will not hide automatically*/}
              {/* msg will be static and it will not hide automatically*/}
              {/* defining session based msg */}
              {/* delete modal start */}	
              <div className="modal small fade autoWidthModal wordBreak" id="deleteModel" tabIndex={-1} role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                      <h4 className="modal-title" id="myModalLabel"> Confirm to delete </h4>
                    </div>
                    <div className="modal-body stripeBg">
                      <h5 className="modal-title"> Are you sure want to delete this record? </h5>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-default" data-dismiss="modal">Cancel</button>
                      <button type="button" id="delModalBtn" className="btn btn-danger"> Delete </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* delete modal end */}
              <div className="modal small fade autoWidthModal wordBreak" id="customErrorMsgModel" tabIndex={-1} role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title" id="customErrorMsgTitle"></h4>
                    </div>
                    <div className="modal-body stripeBg">
                      <h5 className="modal-title" id="customErrorMsg"></h5>
                    </div>
                    <div className="modal-footer">
                      <button type="button" id="errorCloseBtn" className="btn-u btn-u-red" data-dismiss="modal"> Close </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="alert alert-warning alert-white rounded">
                <div className="icon"><i className="fas fa-exclamation-triangle" /></div>
                {/* <strong>Alert!</strong> */}
                <span className="color-red">
                  Teachers can't see your phone. Please verify your phone number to fix this.
                </span>
              </div>
              <div className="modal fade autoWidthModal wordBreak in" id="autoCorrectPhoneNumberModal" tabIndex={-1} role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                <div className="modal-dialog" style={{}}>
                  <div className="modal-content" style={{maxWidth: '400px'}}>
                    <div className="modal-header">
                      <p className="modal-title">Invalid phone</p>
                    </div>
                    <div className="modal-body stripeBg">
                      <p><span id="m_wrongPhoneNumber" /> is invalid.</p>
                      <p>Do you mean</p><p><span id="m_autoCorrectedPhoneNumber" /></p>
                      <span id="m_autoCorrectedPhoneNumberWithoutCode" style={{display: 'none'}} />
                    </div>
                    <div className="modal-footer">
                      <div className="text-right">
                        <button type="button" className="btn btn-default btn-brd clsCloseButtonOfCorrectPhoneModel" data-dismiss="modal">No</button>
                        <button type="button" className="btn btn-primary clsBtnCorrectPhoneNumber">Yes</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* ******************************** UserEducation Organization add model END***********************************  */}
              <div className>
                <h2 className> Phone Numbers </h2>
                <div className>
                  <table className="table table-striped noBorderTable verticalmiddle">
                    <thead>
                      <tr className="hidden">
                        <th> Phone No. </th>
                      
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="hidden-xs-">
                        <td><div className="display-inline-block large" style={{minWidth: '180px'}}>+91-9999999999</div>
                          <div className="display-inline-block">
                            <div className="display-inline-block">
                              <button style={{padding: '3px 16px'}} type="button" onclick="setPhoneCodeAndNo('91','9999999999', '1680691')" className="enableButtonOnWindowLoad btn-u btn-brd btn-brd-hover rounded btn-u-blue btn-u-xs  cls-verify-phone" id="btnVerifyPhone--"> Verify </button>
                            </div>
                            <div className="display-inline-block">
                              <button style={{fontSize: '16px'}} className=" color-red padding-5 btn btn-link enableButtonOnWindowLoad" onclick="deleteUserPhone('1680691','false',1)" title="Delete"><i className="far fa-trash-alt" /> Delete</button>
                              <a className="btn btn-link no-padding" style={{fontSize: '16px'}} title="Edit" href="#"><i className="fas fa-pencil-alt"> Edit</i></a>
                            </div>
                          </div>
                        </td>    
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
           
              {/* ************************************** USER EDUCATION FORM END ************************** */}
              
              {/* End Content */}
            </div>
          </div>{/*/end row*/}
        </div>{/*/container*/}
      </div>
    </>
  )
}

export default Phone_verification