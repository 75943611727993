import Header from './Header';
import Footer from './Footer';
import './App.css';
import Contentone from "./Contentone";
import Findtutor from './components/Findtutor';
import { Route, Routes } from 'react-router-dom';
import SinglePage from './SinglePage';
import TeacherProfile from "./teachers-profile";
import TeacherProfileDashboard from "./Teacher-profile-dashboard"
// import AccountSetting from "./Account-setting";
import VerifyEmail from "./VerifyEmail";
import LoginPage from "./LoginPage"
import TeachersForm from './TeachersForm';
import TeacherFormSubmitted from './TeacherFormSubmitted';
import TeacherEducationForm from './TeacherEducationForm';
import Navigation from "./Navigation";
import Profile from "./Profile";
import Stepform from "./Stepform";
import Teacherform from "./Teacherform";
import Studentform from "./Studentform";
import StudentRequirement from "./StudentRequirement";
import BasicDetail from "./BasicDetail";
import Tutorform from "./Tutorform";
import Phone_verification from "./Phone_verification";
import  NameSetting from "./NameSetting";
import ChangeEmail from "./ChangeEmail";
import ChangeName from "./ChangeName";
import ChangeNumber from "./ChangeNumber";
import ProfilePhoto from "./ProfilePhoto";
import ReferandEarn from "./ReferandEarn";
import RequestTutor from "./RequestTutor";
import StudentAccountSetting from "./StudentAccountSetting";
import SubjectTeach from "./SubjectTeach";
import TeachingandProfessional from "./TeachingandProfessional";
import TeachingDetails from "./TeachingDetails";
import UploadIDProof from "./UploadIDProof"
import AcountSetting from "./AcountSetting";



function App() {
  return (
   <>
 
  <Header/>
 <Routes>
    <Route path="/" element={<Contentone></Contentone>}/>
    <Route path="/findtutor" element={<Findtutor></Findtutor>}/>
    <Route path="/teacher-profile" element={<TeacherProfile/>}/>
    <Route path="/Login" element={<LoginPage/>}/>
    <Route path="/verify" element={<VerifyEmail/>}/>
    <Route path="/stepform" element={<Stepform/>}/>
    <Route path="/Tutorform" element={<Tutorform/>}/>
    <Route path="/Phone_verification" element={<Phone_verification/>}/>
    <Route path="/change-email" element={<ChangeEmail/>}/>
    <Route path="/change-number" element={<ChangeName/>}/>
    <Route path="/Name-setting" element={<ChangeName/>}/>
    <Route path="/job-details" element={<SinglePage/>}/>
    
    <Route path="/teacher-dashboard" element={<TeacherProfileDashboard/>}/>
    <Route path="/teacher-form" element={<TeachersForm/>}/>
    <Route path="/teacher-submit" element={<TeacherFormSubmitted/>}/>
    <Route path="/wallet" element={<ReferandEarn/>}/>
    <Route path="/request-tutor" element={<RequestTutor/>}/>
    <Route path="/request-details" element={<StudentRequirement/>}/>

  
  </Routes>   
  



  {/* <TeachersForm/> */}
  {/* <TeacherFormSubmitted/> */}
  {/* <TeacherEducationForm/> */}
  

  {/* <Teacherform/> */}
  {/* <Studentform/> */}
{/* <AcountSetting/> */}
  {/* <Stepform/> */}
  {/* < NameSetting/>
  <BasicDetail/>
<ChangeEmail/>
<ChangeName/>
<ChangeNumber/>
<ProfilePhoto/> */}
{/* <ReferandEarn/> */}
{/* <RequestTutor/> */}
{/* <StudentAccountSetting/> */}
{/* <SubjectTeach/> */}
{/* <TeachingandProfessional/> */}
{/* <TeachingDetails/> */}
{/* <UploadIDProof/> */}
 {/* <StudentRequirement/> */}
 
  < Footer/>

  </>
  );
}

export default App;
