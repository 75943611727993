import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';




function Arrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", color:"black"}}
      onClick={onClick}
    ></div>
  );
}



const Contentone = () => {
  var settings2 = {
    autoplay:true,
    // dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: 4,
    slidesToScroll: 4,
    nextArrow: <Arrow />,
    prevArrow: <Arrow />,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll:3 ,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
        
      }
    ]
  };


  var settings1 = {
    autoplay:true,
    // dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <Arrow />,
    prevArrow: <Arrow />,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll:3 ,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        dots: true,
        settings: {
          slidesToShow: 2,
          slidesToScroll:2 ,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
        
      }
    ]
  };
  return (
 <>
 <div className='container-fluid '>
 <div className='row content'>
    <div className='col-lg-5 div-1'>
    <h1 className='Educate-text-cls'>EDUCATE!</h1>
    <h1 className='position-relative smart-text'>SMART IS GREAT</h1>
   <p>
   Jaguar is the luxury vehicle brand of Jaguar Land Rover, a British multinational car manufacturer with its headquarters in Whitley, Coventry, England. Jaguar is the luxury vehicle brand of Jaguar Land Rover, a British multinational 
   </p>
    </div>  
    <div className='col-lg-7'>
       <img src="teacher-mk/boy-girls-cartoon.jpg" alt="Not loading" className='img-fluid'/>
    </div>

 </div>
<div className='container '>
  <div className='row'>
   <div className='col-lg-9 col-sm-12 three-boxes-part ma'>
      <div className='row'>
         <input type="text" className='col-md-3 border-2px form-box box-1 bg-clr-green white-border-on-focus' style={{color:"white"}} placeholder='Subject/skill'/>
            
        
         <input type="text" className='col-md-3 border-2px form-box box-1 bg-clr-green white-border-on-focus' style={{color:"white"}} placeholder='Location' />
         <button type='button' className='col-md-2 bg-white form-box box-3' style={{border:"none"}}>
            Search
         </button>
      </div>
    </div>
  </div>
</div>


 </div>

{/* carousel 1 */}
<div>
        
        <div className="container-fluid  slider-hgt mb-5 ma sl-1 ">
          <div className="row custom ">
          <div className="col-10 ma">

        <h2 className=" fw-bold text-center mt-5 mb-5">Teaching Jobs</h2>
        <Slider {...settings1}>
          <div>
            <img src="teacher-mk/teach-1.jpg" alt="not loading" className='crousel-imgs-1 text-center' style={{display:"inline"}} />
  <h4 className="mt-5 text-center fw-bold">Online Teachers</h4>
                      </div>
          <div>
          <img src="teacher-mk/teach.jpg" alt="not loading" className='crousel-imgs-1' style={{display:"inline"}} />
        <h4 className="mt-5  fw-bold">Home Teachers</h4>

          </div>
          <div>
          <img src="teacher-mk/teach-1.jpg" alt="not loading" className="crousel-imgs-1" style={{display:"inline"}}/>
          <h4 className="mt-5  fw-bold">Assignment Help</h4>

          </div>
          <div>
          <img src="teacher-mk/teach.jpg" alt="not loading" className="crousel-imgs-1" style={{display:"inline"}}/>
          <h4 className="mt-5  fw-bold">Teaching Jobs</h4>

          </div>
          <div>
          <img src="teacher-mk/teach-1.jpg" alt="not loading" className="crousel-imgs-1" style={{display:"inline"}}/>
          <h4 className="mt-5  fw-bold">Online Teaching </h4>
          </div>
          <div>
          <img src="teacher-mk/teach-1.jpg" alt="not loading" className="crousel-imgs-1" style={{display:"inline"}}/>
          <h4 className="mt-5  fw-bold ">Teaching Jobs</h4>
          </div>
        </Slider>
        </div>
        </div>
        </div>
      </div>


{/* carousel 1 */}



{/* crousel 2*/}




 <div>
        
        <div className="container-fluid bg-clr slider-hgt  sl-2">
          <div className="row ccc">
          <div className="col-10 ma">

        <h2 className="text-white fw-bold text-center mt-5 mb-5">Availability Teachers</h2>
        <Slider {...settings2}>
          <div>
            <img src="teacher-mk/teacher-1.jpg" alt="not loading"  style={{fontSize: "21px",fontSize: "21px", borderRadius:"50%", width:"158px",height:"158px", display:"inline" }}/>
            <h4 className="mt-5 text-white fw-bold">Teaching Jobs</h4>
                      </div>
          <div>
          <img src="teacher-mk/teacher-2.jpg" alt="not loading" style={{ fontSize: "21px", borderRadius:"50%", width:"158px",height:"158px", display:"inline"  }}/>
          <h4 className="mt-5 text-white fw-bold">Home Teaching</h4>

          </div>
          <div>
          <img src="teacher-mk/teacher-1.jpg" alt="not loading" style={{fontSize: "21px", borderRadius:"50%", width:"158px",height:"158px", display:"inline"  }}/>
          <h4 className="mt-5 text-white fw-bold">Online Teaching</h4>

          </div>
          <div>
          <img src="teacher-mk/teacher-2.jpg" alt="not loading" style={{fontSize: "21px", borderRadius:"50%", width:"158px",height:"158px", display:"inline" , display:"inline" }}/>
          <h4 className="mt-5 text-white fw-bold">Assignment Jobs</h4>

          </div>
          <div>
          <img src="teacher-mk/teacher-1.jpg" alt="not loading" style={{fontSize: "21px", borderRadius:"50%", width:"158px",height:"158px" , display:"inline" }}/>
          <h4 className="mt-5 text-white fw-bold">Teaching Jobs</h4>

          </div>
          <div>
          <img src="teacher-mk/teacher-2.jpg" alt="not loading" style={{fontSize: "21px", borderRadius:"50%", width:"158px",height:"158px", display:"inline"  }}/>
          <h4 className="mt-5 text-white fw-bold">Teaching Jobs</h4>

          </div>
        </Slider>
        </div>
        </div>
        </div>
      </div>




{/* carousel 2*/}





<div className='container mt-5 con-pad'>
   <div className='row'>
<div className='col-lg-6 col-md-6'>
   <div className='d-flex'>
<div classname="s" style={{ backgroundColor: "rgb(173,205,75)",
  height:"20px",  width:"13px", marginRight: "11px",  marginTop: "1px"}}></div>
      <h6 style={{    fontSize: "15px",fontWeight:"400",
    opacity: "0.8"}}>World best teachers </h6>
    </div>
   <h2 style={{fontWeight:"700"}}>High Quality Teachers</h2>
   <p className='world-best-teachers-text'>
   Jaguar is the luxury vehicle brand of Jaguar Land Rover, a British multinational car manufacturer with its headquarters in Whitley, Coventry, England. Jaguar is the luxury vehicle brand of Jaguar Land Rover, a British multinational  Jaguar is the luxury vehicle brand of Jaguar Land Rover, a British multinational car manufacturer with its headquarters in Whitley, Coventry, England. Jaguar is the luxury vehicle brand of Jaguar Land Rover, a British multinational  Jaguar is the luxury vehicle brand of Jaguar Land Rover, a British multinational car manufacturer with its headquarters in Whitley, Coventry, England. Jaguar is the luxury vehicle brand of Jaguar Land Rover  Rover, a British multinational car manufacturer with its headquarters in Whitley, Coventry, England. Jaguar is the luxury vehicle brand of Jaguar Land Rover, a British multinational  Jaguar is the luxury vehicle brand of Jaguar Land Rover, a British multinational
   </p>
</div>
<div className='col-lg-5 col-md-6 girl-image'>
   <img src="teacher-mk/girl-teacher.jpg" alt="img not loading" className="img-fluid girl-image"/>
</div>

   </div>
</div>
<div className='container-fluid text-div'>
   <div className='row'>
      <div className='col-lg-2 col-md-4 col-sm-2 col-4 icons icon-1 text-center'>

      <img src='teacher-mk/books-stack-of-three.png' alt="not loading" class="books-img" />
      <h2 className=' fw-bold '>9700</h2>
      <h4 className='text-white text-sm'>Subjects</h4>   

      </div>

      <div className='col-lg-2 col-md-4 col-sm-2 col-4 icons icon-2 text-center'>   

<img src='teacher-mk/books-stack-of-three.png' alt="not loading" class="books-img" />
<h2 className=' fw-bold '>1800+</h2>
<h4 className='text-white text-sm'>Skills</h4>  

</div>

<div className='col-lg-2 col-md-4 col-sm-2  col-4 icons icon-3 text-center'>

<img src='teacher-mk/books-stack-of-three.png' alt="not loading" class="books-img" />
<h2 className=' fw-bold '>12000+</h2>
<h4 className='text-white text-sm'>Language </h4>  

</div>

<div className='col-lg-6 col-sm-0 col-md-0 icons icon-3'>

   </div>
   </div>
</div>
<div className='container'>
<div className='row phone-picker-full-div'>
   <div className='col-md-6 col-sm-12'>
   <img src="teacher-mk/phone-picker.jpg" alt="not loading" className='img-fluid phone-picker-img' />

   </div>
   <div className='col-md-6 col-sm-12 padding-576' >
<div className='d-flex'>
<div classname="s" className='sm-div'></div>
<p className='mt-5'>Find the Right Tutor for You</p>
</div>
<h2>What We Do?</h2>
<p className=''>
Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.<br/>
It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text.
</p>
   </div>
</div>
   </div>
   <div className='container-fluid bg-clr' style={{overflow:"hidden"}}>
<div className='row sub-full-div'>
   <div className='d-flex'>
   <div classname="s for-we-text" style={{ backgroundColor: "rgb(173,205,75)",
  height:"20px",  width:"13px",marginLeft:"72px", marginRight: "11px",  marginTop: "9px"}}></div>
      <p style={{fontWeight:"500"}} class="text-white we-text " >We are Provide</p>
      </div>
      <h2 class="text-white Top-sub-text">Top Subjects <span class="txt-clr">and skills</span> </h2>
   <div className='col-lg-3 col-md-3 col-sm-6'>

 <p class="text-white">  Academic Writing  <br/>
Analog Electronics<br/>
BioChemistry<br/>
C/C++<br/>
Commerce<br/>
Computer Science<br/>
C#<br/>
Biotechnology<br/>
Chemistry<br/>
Company Law<br/>
Communication Skills<br/>
Business Management Engineering Mechanics<br/>
Civil Engineering<br/>
Computer networking Digital Electronics<br/>
Fluid Mechanics<br/>
</p>
   </div>

   <div className='col-lg-3 col-md-3 col-sm-6'>
   <p class="text-white">  Academic Writing  <br/>
Analog Electronics<br/>
BioChemistry<br/>
C/C++<br/>
Commerce<br/>
Computer Science<br/>
C#<br/>
Biotechnology<br/>
Chemistry<br/>
Company Law<br/>
Communication Skills<br/>
Business Management Engineering Mechanics<br/>
Civil Engineering<br/>
Computer networking Digital Electronics<br/>
Fluid Mechanics<br/>
</p>

   </div>
   <div className='col-lg-3 col-md-3 col-sm-6 '>
   <p class="text-white">  Academic Writing  <br/>
Analog Electronics<br/>
BioChemistry<br/>
C/C++<br/>
Commerce<br/>
Computer Science<br/>
C#<br/>
Biotechnology<br/>
Chemistry<br/>
Company Law<br/>
Communication Skills<br/>
Business Management Engineering Mechanics<br/>
Civil Engineering<br/>
Computer networking Digital Electronics<br/>
Fluid Mechanics<br/>
</p>

   </div>
   <div className='col-lg-3 col-md-3 col-sm-6 '>
   <p class="text-white">  Academic Writing  <br/>
Analog Electronics<br/>
BioChemistry<br/>
C/C++<br/>
Commerce<br/>
Computer Science<br/>
C#<br/>
Biotechnology<br/>
Chemistry<br/>
Company Law<br/>
Communication Skills<br/>
Business Management Engineering Mechanics<br/>
Civil Engineering<br/>
Computer networking Digital Electronics<br/>
Fluid Mechanics<br/>
</p>

   </div>
</div>
   </div>

   <div className='container-sm overflow-hidden '>
<div className='row map-full-div '>
   <div className='col-lg-7 col-md-6 hgt pad'>
      <div className='ml'>
         <div className='d-flex'>
         <div classname="s" style={{ backgroundColor: "rgb(173,205,75)",
  height:"20px",  width:"13px", marginRight: "11px",  marginTop: "10px"}}></div>
   <p>More Than 40,000 Tutors In Our Network</p>
   </div>
   <h2 style={{ fontWeight: "700" ,margiTop:"10px"}}>Find Anywhere</h2>
   <label>Subject</label>  <br/>
   <input type='text'  className=' form-txt'/>  <br/>
   <label>Location</label>  <br/>

   <input type='text' className=' form-txt'/>
   <br/>
   <button style={{borderRadius:"0px" , marginTop:"33px", color:"white", width:"114px", backgroundColor: "rgb(173,205,75)"}} className='btn'>Search</button>
   </div>
   </div>
   <div className='col-lg-5 col-md-6'>
      <img src="teacher-mk/map-img.jpg" alt="img not loading" classname="img-fluid map-img" style={{
          borderRadius: "61px 0px 61px 0px",
          width: "100%"
      }}/>
   </div>
   </div>
   </div>
  </>
  )
}

export default Contentone

