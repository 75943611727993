import React from 'react'

const ChangeName = () => {
  return (
  <>
  <section class="py-5">
      <div class="container">
        <div class="change_Numbers_page">
          <h3>Change name</h3>
          <label for="exampleInputEmail1" class="form-label mt-5">Full name</label>
          <div class="input-group">
            <input type="email" class="form-control Old_email" id="exampleInputEmail1" aria-describedby="emailHelp" value="Marcia Rhodes " />
          </div>
          <label for="exampleInputEmail1" class="form-label mt-3">ID Proof</label>
          <div class="input-group">
            <input type="file" class="form-control Old_email" id="exampleInputEmail1" aria-describedby="emailHelp" />
          </div>
          <button>Save</button>
        </div>
      </div>
    </section>
  </>
  )
}

export default ChangeName