import React from "react";
import { NavLink } from "react-router-dom";

function Studentform() {
  return (
    <>
     <form action="index.html" method="post">
        <h1>Student details</h1>
        <fieldset>
          <legend><span className="number">1</span> Your basic info</legend>
          <label htmlFor="name">I am:</label>
          <input type="text" id="name" name="user_name" />

          <label htmlFor="name">Location</label>
          <input type="text" id="name" name="user_name" />

          <label htmlFor="name">Phone:</label>
          <input type="text" id="name" name="user_name" />

          <label htmlFor="name">Details of your requirement:</label>
          <textarea name="" id="" cols="30" rows="10"></textarea>

          <label htmlFor="name">Subjects:</label>

          <input type="text" id="name" name="user_name" />
          <label htmlFor="name">Level:</label>
          <input type="text" id="name" name="user_name" />
          <label htmlFor="name">I want:</label>
<select id="name" name="user_name">
    <option value="option1">Option 1</option>
    <option value="option2">Option 2</option>
    <option value="option3">Option 3</option>
</select>
          <legend><span className="number">2</span>Address (Publicly visible)</legend>

          <label htmlFor="mail">Location:</label>
          <input type="text" id="mail" name="user_email" />

          <label htmlFor="mail">Postal code:</label>
          <input type="text" id="mail" name="user_email" />

          <label htmlFor="mail">Phone:</label>
          <input type="text" id="mail" name="user_email" />
          <legend><span className="number">3</span> Subjects you Teach</legend>

          <label htmlFor="mail">Subject (one at a time):</label>
          <input type="text" id="mail" name="user_email" />
          <label htmlFor="mail">From level:</label>
          <input type="text" id="mail" name="user_email" />
          <label htmlFor="mail">To level:</label>
          <input type="text" id="mail" name="user_email" />
          <legend><span className="number">4</span> Add Education/Certification</legend>
          <label htmlFor="mail">Institution name with city:</label>
          <input type="text" id="mail" name="user_email" />
          <label htmlFor="mail">Degree type:</label>
          <input type="text" id="mail" name="user_email" />
        
          <label htmlFor="mail">Start Date:</label>
          <input type="text" id="mail" name="user_email" />
          <label htmlFor="mail">End Date (Leave blank if not complete):</label>
          <input type="text" id="mail" name="user_email" />
        
          <label htmlFor="mail">Association:</label>
          <input type="text" id="mail" name="user_email" />
        
          <label htmlFor="mail">Speciality (optional):</label>
          <input type="text" id="mail" name="user_email" />
        
          <label htmlFor="mail">Score (optional):</label>
          <input type="text" id="mail" name="user_email" />




          <label htmlFor="password">Details of your requirement:</label>
          <textarea></textarea>
          <label>Suggestion Subjects:</label>
          <input type="text" id="name" name="user_name" />
          {/* <input type="radio" id="under_13" defaultValue="under_13" name="user_age" /><label htmlFor="under_13" className="light">Under 13</label><br />
          <input type="radio" id="over_13" defaultValue="over_13" name="user_age" /><label htmlFor="over_13" className="light">13 or Older</label> */}
        </fieldset>
        <fieldset>
</fieldset>
        <button type="submit">Submitt</button>
      </form>


   </>
  );
}

export default Studentform;